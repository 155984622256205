

/////   STYLES

.notification {
  & + .notification {
    margin-bottom: 10px;
  }}

.notification-stack {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.dashboard {
  display: flex;

}

.manager-header{

  .button-container{
    display: flex;
    align-items: center;
    margin-top: 5vh;
  }

}

// .sidebar {
//   flex-basis: 25vw; /* or whatever width you prefer */
//   background-color: #f8f9fa; /* light gray background */
//   border-right: 1px solid #dee2e6; /* light border */
//   overflow-y: auto; /* in case the content exceeds the viewport height */

// }

// .main-content {
//   flex-grow: 1; /* takes up the remaining space */
//   overflow-y: auto; /* in case the content exceeds the viewport height */
// }

.navigation {
  border-width: 0px !important;
  width:100vw;
  flex-shrink:0;
  .current{
    background-color:#445069;
    color:#EFF0F0;
   
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
 

}
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px!important;

  .logo {
    font-size: 1.5em;
    padding: 10;
  }

  .nav-links {
    display: flex;
    gap: 1em;

    @media (max-width: 768px) {
      display: none;
    }
  }
  .nav-link {
    text-decoration: none;

  }
  
}



.manager-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: auto;
  margin-top: 5vh;
}

.list-group {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column; 
  // max-width: 900px; /* Set the maximum width for the list group */
  // width: 100%; /* Ensure it takes up the full width of its container up to 600px */
  // margin: auto; /* Center the list group horizontally */
  padding: 10px !important;
  max-width: 900px;
  margin: auto;
  
  display: flex!important;

  img{
      width:65px;
      height:65px;
      border-radius:5px;
  }

}

.list-item {
  border-radius: 10px !important;
  width: 100%; /* Make each list item take the full width of the list group */
  margin-top: 1vh;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.login-form {
  max-width: 600px;
  // Other styles...
}


.flex-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  flex-basis: 250px;
  // background-color: #f8f9fa;
  // border-right: 1px solid #dee2e6;
  overflow-y: auto;
  min-height:100vh;
}

.main-content {
  flex-grow: 1;
  
  min-height: 100vh;
}

.mobile-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.mobile-navbar {
  flex-shrink: 0; /* Ensure navbar doesn't shrink */
}

.mobile-content {
  flex-grow: 1;
  
  min-height: 100vh;
}

.custom-accordion .accordion-header {
  cursor: pointer;
}

.custom-accordion .accordion-body {
  padding: 10px;
  border: 0px solid #e0e0e0;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.loading-container i {
  font-size: 5em;
  margin-bottom: 20px;
  
}

.loading-container p {
  font-size: 1.5em;
}






//////COLOR PALETTES
/// --DARK--
/// primary: bright
/// secondary: pastel
/// 
/// label: pastel dark
/// container: light
/// item: pastel normal
/// 
/// header: pastel dark
/// background: pastel darkest
/// footer: pastel normal
/// 
/// font: dark
/// font-2: slightly dark
/// link: bright
/// link-2: light



$palette-d-forest:(
  primary: #C24E34, //+
  secondary: #000,
  active: #000,
  disabled: #000,

  //container
  label: #0a2d28, //+
  container: #4d584a, //+
  item: #49ABA2,  //+
 
  //body
  header: #011302,  //+
  background: #050e0d,  //+  1F4743 091716
  footer: #3D8F87,  //+
  // 011502
  //fonts
  font: #011302,  //+ BBEDF2
  font-2: #BBEDF2,  // 011302
  link: #C24E34,
  link-2: #8998A9,
);

$palette-d-tangerine:(
  primary: #DC2F02, //+
  secondary: #ec5b0d, //
  active: #000,
  disabled: #000,

  //container
  label: #F09E05,   //+
  container: #883203, //+ ce7909 883203
  item: #F09E05,
  //f09e05
  //body
  header: #251104,    //+
  background: #1c0500,  //+ 651501
  footer: #FAAF4C,
  //1f0e03
  //fonts
  font: #251104,  //+
  font-2: #FFEEC2,  //+
  link: #DC2F02,
  link-2: #8998A9,
);         

$palette-d-arctic:(
  primary: #C96A16,   
  secondary: #000,    
  active: #000,
  disabled: #000,

  //container
  label: #156373, // 156373
  container: #23555e, //bccdd1
  item: #75ABBC,
 
  //body
  header: #11272E,
  background: #0b1619,  // 1E4148
  footer: #8998a9,
  
  //fonts
  font: #323A3C,  //323A3C
  font-2: #DFE0E2,  //DFE0E2
  link: #23B5D3,
  link-2: #8998A9,

);

$palette-d-grape:(  //NEEDS SOME COLORS
  primary: #23B5D3,   //+
  secondary: #C96A16,    
  active: #000,
  disabled: #000,

  //container
  label: #522B5B,   //+
  container: #1b0b1e, //+  4e1e56
  item: #A36686, //+
 
  //body
  header: #190019,  //+
  background: #10071c, //+  2b124c
  footer: #854F6C,  //+
  
  //fonts
  font: #190019, //+
  font-2: #FBE4D8,  //+
  link: #23B5D3,   //+
  link-2: #8998A9,
  );

  $palette-d-peach:(
  primary: #F39F5A,   //+
  secondary: #000,    

  //container
  label: #592140, //+
  container: #3e2f50, //+
  item: #B0455C,  //+
 
  //body
  header: #1d1a39,  //+
  background: #110920,  //+ 34133F 41184E 190E2E
  footer: #843344,    //+
  
  //fonts
  font: #1d1a39,  //+
  font-2: #e8dad9,  //+
  link: #F39F5A,  //+
  link-2: #8998A9,
);

$palette-d-coconut:(
  primary: #69554F,   //+
  secondary: #b9f4da,    
  active: #000,
  disabled: #000,

  //container
  label: #4f463a, //+ #FFE8C9  70ABAF
  container: #2e3c32, //+  ece5c5
  item: #646a37,  //+ #5B6706 99E1D9
 
  //body
  header: #32292f,   //+
  background: #151818,  //+ 4B5857  272e2e
  footer: #4B5857,    //+
  
  //fonts
  font: #32292f,   //+
  font-2: #F0F7F4,   //+
  link: #b9f4da,    //+ #171717  69554F
  link-2: #8998A9,
);



//                        LIGHT THEMES
$palette-l-forest:(
  primary: #C24E34, //+
  secondary: #000,
  active: #000,
  disabled: #000,

  //container
  label: #b1bdbc, //+ 13675C
  container: #e5eee3, //+ CFE2CA
  item: #49ABA2,  //+ 49ABA2
 
  //body
  header: #011302,  //+ 011302
  background: #e5eeee,  //+ 1F4743
  footer: #538a84,  //+ 3D8F87
  // 011502
  //fonts
  font: #BBEDF2,  //+ BBEDF2
  font-2: #011302, // 011302
  link: #C24E34,  // C24E34
  link-2: #8998A9, //8998A9
);

$palette-l-tangerine:(
  primary: #DC2F02, //+
  secondary: #f09e05,
  active: #000,
  disabled: #000,

  //container
  label: #ede4ce,   //+  F09E05
  container: #ead0ad, //+  FBC174
  item: #F09E05,
  //f09e05
  //body
  header: #251104,    //+
  background: #f8efda,  //+  651501
  footer: #FAAF4C,  //FAAF4C
  //1f0e03
  //fonts
  font: #FFEEC2,  //+  251104
  font-2: #251104,  //+  FFEEC2
  link: #DC2F02,
  link-2: #8998A9,
);         

$palette-l-arctic:(
  primary: #C96A16,   
  secondary: #4084bf,    
  active: #000,
  disabled: #000,

  //container
  label: #bccdd1, // 156373
  container: #bccdd1, // bccdd1
  item: #75ABBC,  //
 
  //body
  header: #11272E,    //  11272E
  background: #DFE0E2,  // 1E4148
  footer: #8998a9,    // 8998a9
  
  //fonts
  font: #DFE0E2,  // 323A3C
  font-2: #323A3C,    // DFE0E2
  link: #23B5D3,
  link-2: #8998A9,

);

$palette-l-grape:(  //NEEDS SOME COLORS
  primary: #23B5D3,   //+
  secondary: #C96A16,    
  active: #000,
  disabled: #000,

  //container
  label: #ebd6ca,   //+
  container: #f8e6dc, //+ DFB6B2
  item: #A36686, //+
 
  //body
  header: #190019,  //+
  background: #f7efeb, //+ 2b124c
  footer: #854F6C,  //+ 854F6C
  
  //fonts
  font: #FBE4D8, //+ 190019
  font-2: #190019,  //+
  link: #23B5D3,   //+
  link-2: #8998A9,
  );

  $palette-l-peach:(
  primary: #F39F5A,   //+
  secondary: #000,    

  //container
  label: #d1bac6, //+  592140
  container: #cbc2d8, //+
  item: #B0455C,  //+
 
  //body
  header: #1d1a39,  //+
  background: #ebe9ef,  //+ 41184E
  footer: #843344,    //+
  
  //fonts
  font: #F0F7F4,  //+
  font-2: #1d1a39,  //+
  link: #B0455C,  //+  F39F5A
  link-2: #8998A9,
);

$palette-l-coconut:(
  primary: #69554F,   //+
  secondary: #000,    
  active: #000,
  disabled: #000,

  //container
  label: #b1cacc, //+  70ABAF
  container: #ceeae6, //+ C5ECE7
  item: #99E1D9,  //+
 
  //body
  header: #32292f,   //+
  background: #F0F7F4,  //+ 4B5857
  footer: #b1cacc,    //+ 4B5857
  
  //fonts
  font: #F0F7F4,   //+
  font-2: #32292f,   //+ F0F7F4
  link: #69554F,    //+
  link-2: #8998A9,
);

///// MIXINS

@mixin theme($palette) {
 

  body{

  color: map-get($palette, font)!important;
  background-color: map-get($palette, background) !important;
  }

  a {
    color: map-get($palette, link) !important;  
  }

  a:hover{
    color: map-get($palette, primary) !important;  
  }

  .header{
    color: map-get($palette, font) !important;
    background-color: map-get($palette, header) !important;
  }

  .container {
    background-color: map-get($palette, container) !important;
    color: map-get($palette, font) !important;
  }

  // .navbar {
  //   background-color: map-get($palette, label) !important;
  // }

  .dashboard {
    background-color: map-get($palette, header) !important;
   
  }

  .footer {
    background-color: map-get($palette, footer) !important;
  }

  .list{
    background-color: map-get($palette, container) !important;
    color: map-get($palette, font-2) !important;
    .item{
      background-color: map-get($palette, item) !important;
      color: map-get($palette, font-2) !important;
    }
  }

  .label{
    background-color: map-get($palette, label) !important;
  }

  .dashboard{
    background-color: map-get($palette, background) !important;
    color: map-get($palette, font) !important;
  }

  .primary{
    background-color: map-get($palette, primary) !important;
  }

  .tabs{
    .active{
      background-color: map-get($palette, container) !important;
    }

    .current{
      background-color: map-get($palette, container) !important;
    }
  }

  .fa, .fas, .far, .fal {
    color: map-get($palette, primary) !important;
  }

  .button{
    
    .primary{
      color: map-get($palette, primary) !important;
    }

  }
  .notification-stack {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  } 
  
  .notification {
  
  
    &.success {
      background-color: map-get($palette, primary) !important;
    }
  
    &.info {
      background-color: map-get($palette, label) !important;
    }
  
    &.fail {
      background-color: map-get($palette, footer) !important;
    }
  }

  h1,h2,h3,h4,h5,h6{
    color: map-get($palette, font-2) !important;
  }

  .title{
    color: map-get($palette, font-2) !important;
  }

  .font{  
    color: map-get($palette, font) !important;
  }
  .font-2{
    color: map-get($palette, font-2) !important;
  }

  .sidebar{
    background-color: map-get($palette, label) !important;
    color: map-get($palette, font) !important;
    .sibebar-item{
      background-color: map-get($palette, label) !important;
      color: map-get($palette, font) !important;
    }
    .sidebar-item:hover{
      background-color: map-get($palette, secondary) !important;
      color: map-get($palette, link-2) !important;
    }
    .active{
      background-color: map-get($palette, secondary) !important;
      color: map-get($palette, link-2) !important;
      border-color: map-get($palette, link-2) !important;
    }

  }

  .navigation{

border-width: 0;


    .navigation-item:hover{
      background-color: map-get($palette, secondary) !important;
      color: map-get($palette, font-2) !important;
    }

    .active{
      color: map-get($palette, primary) !important;
    }
  }

  .nav{
    border-width: 0px;
    border-color: map-get($palette, container) !important;
      // color: map-get($palette, font-2) !important;
  }

}


body.tangerine-d {
  @include theme($palette-d-tangerine);
}

body.forest-d {
  @include theme($palette-d-forest);
}

body.arctic-d {
  @include theme($palette-d-arctic);
}

body.grape-d {
  @include theme($palette-d-grape);
}

body.peach-d {
  @include theme($palette-d-peach);
}

body.coconut-d {
  @include theme($palette-d-coconut);
}

body.tangerine-l {
  @include theme($palette-l-tangerine);
}

body.forest-l {
  @include theme($palette-l-forest);
}

body.arctic-l {
  @include theme($palette-l-arctic);
}

body.grape-l {
  @include theme($palette-l-grape);
}

body.peach-l {
  @include theme($palette-l-peach);
}

body.coconut-l {
  @include theme($palette-l-coconut);
}